/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from "dayjs";
import { deleteItems, toChunks } from "../utils/array";
import { VirtualizedGrouping } from "../utils/virtualized-grouping";
import { createKeySelector, getSortSelectors, groupArray } from "../utils/grouping";
import { sql } from "kysely";
import { MAX_SQL_PARAMETERS } from "../database/sql-collection";
export default class Trash {
    constructor(db) {
        this.db = db;
        this.collections = ["notes", "notebooks"];
        this.cache = {
            notebooks: [],
            notes: []
        };
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.cleanup();
            yield this.buildCache();
        });
    }
    buildCache() {
        return __awaiter(this, void 0, void 0, function* () {
            this.cache.notes = [];
            this.cache.notebooks = [];
            const result = yield this.db
                .sql()
                .selectFrom("notes")
                .where("type", "==", "trash")
                .select(["id", sql `'note'`.as("itemType")])
                .unionAll((eb) => eb
                .selectFrom("notebooks")
                .where("type", "==", "trash")
                .select(["id", sql `'notebook'`.as("itemType")]))
                .execute();
            for (const { id, itemType } of result) {
                if (itemType === "note")
                    this.cache.notes.push(id);
                else if (itemType === "notebook")
                    this.cache.notebooks.push(id);
            }
        });
    }
    cleanup() {
        return __awaiter(this, void 0, void 0, function* () {
            const duration = this.db.settings.getTrashCleanupInterval();
            if (duration === -1 || !duration)
                return;
            const maxMs = dayjs().subtract(duration, "days").toDate().getTime();
            const expiredItems = yield this.db
                .sql()
                .selectNoFrom((eb) => [
                eb
                    .selectFrom("notes")
                    .where("type", "==", "trash")
                    .where("dateDeleted", "<=", maxMs)
                    .select("id")
                    .as("noteId"),
                eb
                    .selectFrom("notebooks")
                    .where("type", "==", "trash")
                    .where("dateDeleted", "<=", maxMs)
                    .select("id")
                    .as("notebookId")
            ])
                .execute();
            const { noteIds, notebookIds } = expiredItems.reduce((ids, item) => {
                if (item.noteId)
                    ids.noteIds.push(item.noteId);
                if (item.notebookId)
                    ids.notebookIds.push(item.notebookId);
                return ids;
            }, { noteIds: [], notebookIds: [] });
            yield this._delete(noteIds, notebookIds);
        });
    }
    add(type_1, ids_1) {
        return __awaiter(this, arguments, void 0, function* (type, ids, deletedBy = "user") {
            if (type === "note") {
                yield this.db.notes.collection.update(ids, {
                    type: "trash",
                    itemType: "note",
                    dateDeleted: Date.now(),
                    deletedBy
                });
                this.cache.notes.push(...ids);
            }
            else if (type === "notebook") {
                yield this.db.notebooks.collection.update(ids, {
                    type: "trash",
                    itemType: "notebook",
                    dateDeleted: Date.now(),
                    deletedBy
                });
                this.cache.notebooks.push(...ids);
            }
        });
    }
    delete(...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            if (ids.length <= 0)
                return;
            const noteIds = [];
            const notebookIds = [];
            for (const id of ids) {
                const isNote = this.cache.notes.includes(id);
                if (isNote) {
                    noteIds.push(id);
                    this.cache.notes.splice(this.cache.notes.indexOf(id), 1);
                }
                else if (!isNote) {
                    notebookIds.push(id);
                    this.cache.notebooks.splice(this.cache.notebooks.indexOf(id), 1);
                }
            }
            yield this._delete(noteIds, notebookIds);
        });
    }
    _delete(noteIds, notebookIds) {
        return __awaiter(this, void 0, void 0, function* () {
            if (noteIds.length > 0) {
                for (const chunk of toChunks(noteIds, MAX_SQL_PARAMETERS)) {
                    yield this.db.content.removeByNoteId(...chunk);
                    yield this.db.noteHistory.clearSessions(...chunk);
                    yield this.db.notes.remove(...chunk);
                    deleteItems(this.cache.notes, ...chunk);
                }
            }
            if (notebookIds.length > 0) {
                const ids = [...notebookIds, ...(yield this.subNotebooks(notebookIds))];
                for (const chunk of toChunks(ids, MAX_SQL_PARAMETERS)) {
                    yield this.db.notebooks.remove(...chunk);
                    yield this.db.relations.unlinkOfType("notebook", chunk);
                    deleteItems(this.cache.notebooks, ...chunk);
                }
            }
        });
    }
    restore(...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            if (ids.length <= 0)
                return;
            const noteIds = [];
            const notebookIds = [];
            for (const id of ids) {
                const isNote = this.cache.notes.includes(id);
                if (isNote) {
                    noteIds.push(id);
                    //  this.cache.notes.splice(this.cache.notes.indexOf(id), 1);
                }
                else if (!isNote) {
                    notebookIds.push(id);
                    // this.cache.notebooks.splice(this.cache.notebooks.indexOf(id), 1);
                }
            }
            if (noteIds.length > 0) {
                yield this.db.notes.collection.update(noteIds, {
                    type: "note",
                    dateDeleted: null,
                    itemType: null,
                    deletedBy: null
                });
                deleteItems(this.cache.notes, ...noteIds);
            }
            if (notebookIds.length > 0) {
                const ids = [...notebookIds, ...(yield this.subNotebooks(notebookIds))];
                yield this.db.notebooks.collection.update(ids, {
                    type: "notebook",
                    dateDeleted: null,
                    itemType: null,
                    deletedBy: null
                });
                deleteItems(this.cache.notebooks, ...ids);
            }
        });
    }
    clear() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._delete(this.cache.notes, this.cache.notebooks);
            this.cache = { notebooks: [], notes: [] };
        });
    }
    // synced(id: string) {
    //   // const [item] = this.getItem(id);
    //   if (item && item.itemType === "note") {
    //     const { contentId } = item;
    //     return !contentId || this.db.content.exists(contentId);
    //   } else return true;
    // }
    all(deletedBy) {
        return __awaiter(this, void 0, void 0, function* () {
            return [
                ...(yield this.trashedNotes(this.cache.notes, deletedBy)),
                ...(yield this.trashedNotebooks(this.cache.notebooks, deletedBy))
            ];
        });
    }
    trashedNotes(ids, deletedBy) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.db
                .sql()
                .selectFrom("notes")
                .where("type", "==", "trash")
                .where("id", "in", ids)
                .$if(!!deletedBy, (eb) => eb.where("deletedBy", "==", deletedBy))
                .selectAll()
                .execute());
        });
    }
    trashedNotebooks(ids, deletedBy) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.db
                .sql()
                .selectFrom("notebooks")
                .where("type", "==", "trash")
                .where("id", "in", ids)
                .$if(!!deletedBy, (eb) => eb.where("deletedBy", "==", deletedBy))
                .selectAll()
                .execute());
        });
    }
    grouped(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const ids = [...this.cache.notes, ...this.cache.notebooks];
            const selector = getSortSelectors(options)[options.sortDirection];
            return new VirtualizedGrouping(ids.length, this.db.options.batchSize, () => Promise.resolve(ids), (start, end) => __awaiter(this, void 0, void 0, function* () {
                const notesRange = end < this.cache.notes.length
                    ? [start, end]
                    : [start, this.cache.notes.length];
                const notebooksRange = start >= this.cache.notes.length
                    ? [start, end]
                    : [0, Math.min(this.cache.notebooks.length, end)];
                const items = [
                    ...(yield this.trashedNotes(this.cache.notes.slice(notesRange[0], notesRange[1]), "user")),
                    ...(yield this.trashedNotebooks(this.cache.notebooks.slice(notebooksRange[0], notebooksRange[1]), "user"))
                ];
                items.sort(selector);
                return {
                    ids: ids.slice(start, end),
                    items
                };
            }), (items) => groupArray(items, createKeySelector(options)), () => __awaiter(this, void 0, void 0, function* () {
                const items = yield this.all();
                items.sort(selector);
                return Array.from(groupArray(items, createKeySelector(options)).values());
            }));
        });
    }
    /**
     *
     * @param {string} id
     */
    exists(id) {
        return this.cache.notebooks.includes(id) || this.cache.notes.includes(id);
    }
    subNotebooks(notebookIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const ids = yield this.db
                .sql()
                .withRecursive(`subNotebooks(id)`, (eb) => eb
                .selectFrom((eb) => sql `(VALUES ${sql.join(notebookIds.map((id) => eb.parens(sql `${id}`)))})`.as("notebookIds"))
                .selectAll()
                .unionAll((eb) => eb
                .selectFrom(["relations", "subNotebooks", "notebooks"])
                .select("relations.toId as id")
                .where("toType", "==", "notebook")
                .where("fromType", "==", "notebook")
                .whereRef("fromId", "==", "subNotebooks.id")
                .where((eb) => eb
                .selectFrom("notebooks")
                .whereRef("notebooks.id", "==", "relations.toId")
                .where("notebooks.type", "==", "trash")
                .limit(1)
                .select("deletedBy"), "!=", "user")
                .$narrowType()))
                .selectFrom("subNotebooks")
                .select("id")
                .execute();
            return deleteItems(ids.map((ref) => ref.id), ...notebookIds);
        });
    }
}
