/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { match } from "fuzzyjs";
import { sql } from "kysely";
import { VirtualizedGrouping } from "../utils/virtualized-grouping";
import { logger } from "../logger";
import { rebuildSearchIndex } from "../database/fts";
export default class Lookup {
    constructor(db) {
        this.db = db;
    }
    notes(query, notes) {
        return this.toSearchResults((limit) => __awaiter(this, void 0, void 0, function* () {
            if (query.length < 3)
                return [];
            const db = this.db.sql();
            const excludedIds = this.db.trash.cache.notes;
            const results = yield db
                .selectFrom((eb) => eb
                .selectFrom("notes_fts")
                .$if(!!notes, (eb) => eb.where("id", "in", notes.filter.select("id")))
                .$if(excludedIds.length > 0, (eb) => eb.where("id", "not in", excludedIds))
                .where("title", "match", query)
                .select(["id", sql `rank * 10`.as("rank")])
                .unionAll((eb) => eb
                .selectFrom("content_fts")
                .$if(!!notes, (eb) => eb.where("id", "in", notes.filter.select("id")))
                .$if(excludedIds.length > 0, (eb) => eb.where("id", "not in", excludedIds))
                .where("data", "match", query)
                .select(["noteId as id", "rank"])
                .$castTo())
                .as("results"))
                .select(["results.id"])
                .groupBy("results.id")
                .orderBy(sql `SUM(results.rank)`, "asc")
                .$if(!!limit, (eb) => eb.limit(limit))
                // filter out ids that have no note against them
                .where("results.id", "in", (notes || this.db.notes.all).filter.select("id"))
                .execute()
                .catch((e) => {
                logger.error(e, `Error while searching`, { query });
                return [];
            });
            return results.map((r) => r.id);
        }), notes || this.db.notes.all);
    }
    notebooks(query) {
        return this.search(this.db.notebooks.all, query, [
            { name: "id", column: "notebooks.id", weight: -100 },
            { name: "title", column: "notebooks.title", weight: 10 },
            { name: "description", column: "notebooks.description" }
        ]);
    }
    tags(query) {
        return this.search(this.db.tags.all, query, [
            { name: "id", column: "tags.id", weight: -100 },
            { name: "title", column: "tags.title" }
        ]);
    }
    reminders(query) {
        return this.search(this.db.reminders.all, query, [
            { name: "id", column: "reminders.id", weight: -100 },
            { name: "title", column: "reminders.title", weight: 10 },
            { name: "description", column: "reminders.description" }
        ]);
    }
    trash(query) {
        return {
            sorted: (limit) => __awaiter(this, void 0, void 0, function* () {
                const { ids, items } = yield this.filterTrash(query, limit);
                return new VirtualizedGrouping(ids.length, this.db.options.batchSize, () => Promise.resolve(ids), (start, end) => __awaiter(this, void 0, void 0, function* () {
                    return {
                        ids: ids.slice(start, end),
                        items: items.slice(start, end)
                    };
                }));
            }),
            items: (limit) => __awaiter(this, void 0, void 0, function* () {
                const { items } = yield this.filterTrash(query, limit);
                return items;
            }),
            ids: () => this.filterTrash(query).then(({ ids }) => ids)
        };
    }
    attachments(query) {
        return this.search(this.db.attachments.all, query, [
            { name: "id", column: "attachments.id", weight: -100 },
            { name: "filename", column: "attachments.filename", weight: 5 },
            { name: "mimeType", column: "attachments.mimeType" },
            { name: "hash", column: "attachments.hash" }
        ]);
    }
    search(selector, query, fields) {
        return this.toSearchResults((limit) => this.filter(selector, query, fields, limit), selector);
    }
    filter(selector, query, fields, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, e_1, _b, _c;
            const results = new Map();
            const columns = fields.map((f) => f.column);
            try {
                for (var _d = true, _e = __asyncValues(selector.fields(columns)), _f; _f = yield _e.next(), _a = _f.done, !_a; _d = true) {
                    _c = _f.value;
                    _d = false;
                    const item = _c;
                    if (limit && results.size >= limit)
                        break;
                    for (const field of fields) {
                        const result = match(query, `${item[field.name]}`);
                        if (result.match) {
                            const oldScore = results.get(item.id) || 0;
                            results.set(item.id, oldScore + result.score * (field.weight || 1));
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
            selector.fields([]);
            return Array.from(results.entries())
                .sort((a, b) => a[1] - b[1])
                .map((a) => a[0]);
        });
    }
    toSearchResults(ids, selector) {
        return {
            sorted: (limit) => __awaiter(this, void 0, void 0, function* () { return this.toVirtualizedGrouping(yield ids(limit), selector); }),
            items: (limit) => __awaiter(this, void 0, void 0, function* () { return this.toItems(yield ids(limit), selector); }),
            ids
        };
    }
    filterTrash(query, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            const items = yield this.db.trash.all();
            const results = new Map();
            for (const item of items) {
                if (limit && results.size >= limit)
                    break;
                const result = match(query, item.title);
                if (result.match) {
                    results.set(item.id, { rank: result.score, item });
                }
            }
            const sorted = Array.from(results.entries()).sort((a, b) => a[1].rank - b[1].rank);
            return {
                ids: sorted.map((a) => a[0]),
                items: sorted.map((a) => a[1].item)
            };
        });
    }
    toVirtualizedGrouping(ids, selector) {
        return new VirtualizedGrouping(ids.length, this.db.options.batchSize, () => Promise.resolve(ids), (start, end) => __awaiter(this, void 0, void 0, function* () {
            const items = yield selector.records(ids);
            return {
                ids: ids.slice(start, end),
                items: Object.values(items).slice(start, end)
            };
        }));
    }
    toItems(ids, selector) {
        if (!ids.length)
            return [];
        return selector.items(ids);
    }
    rebuild() {
        return __awaiter(this, void 0, void 0, function* () {
            const db = this.db.sql();
            yield rebuildSearchIndex(db);
        });
    }
}
