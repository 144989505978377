/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { checkSyncStatus, CURRENT_DATABASE_VERSION, EV, EVENTS, sendSyncProgressEvent, SYNC_CHECK_IDS } from "../../common";
import Constants from "../../utils/constants";
import TokenManager from "../token-manager";
import Collector from "./collector";
import * as signalr from "@microsoft/signalr";
import Merger from "./merger";
import { AutoSync } from "./auto-sync";
import { logger } from "../../logger";
import { Mutex } from "async-mutex";
import { migrateItem, migrateVaultKey } from "../../migrations";
import { isDeleted, isTrashItem } from "../../types";
import { SYNC_COLLECTIONS_MAP } from "./types";
import { SyncDevices } from "./devices";
import { DefaultColors } from "../../collections/colors";
export default class SyncManager {
    constructor(db) {
        this.db = db;
        this.sync = new Sync(this.db);
        this.devices = this.sync.devices;
    }
    start(options) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            try {
                if (yield checkSyncStatus(SYNC_CHECK_IDS.autoSync))
                    yield this.sync.autoSync.start();
                yield this.sync.start(options);
                return true;
            }
            catch (e) {
                const isHubException = e.message.includes("HubException:");
                if (isHubException) {
                    const actualError = /HubException: (.*)/gm.exec(e.message);
                    const errorText = actualError && actualError.length > 1
                        ? actualError[1]
                        : e.message;
                    // NOTE: sometimes there's the case where the user has already
                    // confirmed their email but the server still thinks that it
                    // isn't confirmed. This check is added to trigger a force
                    // update of the access token.
                    if ((errorText.includes("Please confirm your email ") ||
                        errorText.includes("Invalid token.")) &&
                        ((_a = (yield this.db.user.getUser())) === null || _a === void 0 ? void 0 : _a.isEmailConfirmed)) {
                        yield this.db.tokenManager._refreshToken(true);
                        return false;
                    }
                    throw new Error(errorText);
                }
                throw e;
            }
        });
    }
    acquireLock(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.sync.autoSync.stop();
                yield callback();
            }
            finally {
                yield this.sync.autoSync.start();
            }
        });
    }
    stop() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.sync.cancel();
        });
    }
}
class Sync {
    constructor(db) {
        this.db = db;
        this.collector = new Collector(this.db);
        this.merger = new Merger(this.db);
        this.autoSync = new AutoSync(this.db, 1000);
        this.logger = logger.scope("Sync");
        this.syncConnectionMutex = new Mutex();
        this.devices = new SyncDevices(this.db.kv, this.db.tokenManager);
        EV.subscribe(EVENTS.userLoggedOut, () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            yield ((_a = this.connection) === null || _a === void 0 ? void 0 : _a.stop());
            this.autoSync.stop();
        }));
    }
    start(options) {
        return __awaiter(this, void 0, void 0, function* () {
            this.createConnection();
            if (!this.connection)
                return;
            if (!(yield checkSyncStatus(SYNC_CHECK_IDS.sync))) {
                yield this.connection.stop();
                return;
            }
            if (!(yield this.db.user.getUser()))
                return;
            this.logger.info("Starting sync", options);
            this.connection.onclose((error = new Error("Connection closed.")) => {
                this.db.eventManager.publish(EVENTS.syncAborted);
                this.logger.error(error);
                throw new Error("Connection closed.");
            });
            const { deviceId } = yield this.init(options.force);
            this.logger.info("Initialized sync", { deviceId });
            if (options.type === "fetch" || options.type === "full") {
                yield this.fetch(deviceId);
                this.logger.info("Data fetched");
            }
            if ((options.type === "send" || options.type === "full") &&
                (yield this.send(deviceId, options.force)))
                this.logger.info("New data sent");
            yield this.stop(options);
            if (!(yield checkSyncStatus(SYNC_CHECK_IDS.autoSync))) {
                yield this.connection.stop();
                this.autoSync.stop();
            }
        });
    }
    init(isForceSync) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.checkConnection();
            if (isForceSync) {
                yield this.devices.unregister();
                yield this.devices.register();
            }
            let deviceId = yield this.devices.get();
            if (!deviceId) {
                yield this.devices.register();
                deviceId = yield this.devices.get();
            }
            if (!deviceId)
                throw new Error("Sync device not registered.");
            return { deviceId };
        });
    }
    fetch(deviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g;
            yield this.checkConnection();
            const key = yield this.db.user.getEncryptionKey();
            if (!key || !key.key || !key.salt) {
                this.logger.error(new Error("User encryption key not generated. Please relogin."));
                EV.publish(EVENTS.userSessionExpired);
                return;
            }
            let count = 0;
            (_a = this.connection) === null || _a === void 0 ? void 0 : _a.off("SendItems");
            (_b = this.connection) === null || _b === void 0 ? void 0 : _b.off("SendVaultKey");
            (_c = this.connection) === null || _c === void 0 ? void 0 : _c.on("SendVaultKey", (vaultKey) => __awaiter(this, void 0, void 0, function* () {
                var _h;
                if (((_h = this.connection) === null || _h === void 0 ? void 0 : _h.state) !== signalr.HubConnectionState.Connected)
                    return false;
                if (vaultKey &&
                    vaultKey.cipher !== null &&
                    vaultKey.iv !== null &&
                    vaultKey.salt !== null &&
                    vaultKey.length > 0) {
                    const vault = yield this.db.vaults.default();
                    if (!vault)
                        yield migrateVaultKey(this.db, vaultKey, 5.9, CURRENT_DATABASE_VERSION);
                }
                return true;
            }));
            (_d = this.connection) === null || _d === void 0 ? void 0 : _d.on("SendItems", (chunk) => __awaiter(this, void 0, void 0, function* () {
                var _j;
                if (((_j = this.connection) === null || _j === void 0 ? void 0 : _j.state) !== signalr.HubConnectionState.Connected)
                    return false;
                yield this.processChunk(chunk, key);
                count += chunk.items.length;
                sendSyncProgressEvent(this.db.eventManager, `download`, count);
                return true;
            }));
            yield ((_e = this.connection) === null || _e === void 0 ? void 0 : _e.invoke("RequestFetch", deviceId));
            (_f = this.connection) === null || _f === void 0 ? void 0 : _f.off("SendItems");
            (_g = this.connection) === null || _g === void 0 ? void 0 : _g.off("SendVaultKey");
            yield this.db
                .sql()
                .updateTable("notes")
                .where("id", "in", (eb) => eb
                .selectFrom("content")
                .select("noteId as id")
                .where("conflicted", "is not", null)
                .where("conflicted", "!=", false)
                .$castTo())
                .set({ conflicted: true })
                .execute();
        });
    }
    send(deviceId, isForceSync) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, e_1, _b, _c;
            var _d;
            yield this.uploadAttachments();
            let done = 0;
            try {
                for (var _e = true, _f = __asyncValues(this.collector.collect(100, isForceSync)), _g; _g = yield _f.next(), _a = _g.done, !_a; _e = true) {
                    _c = _g.value;
                    _e = false;
                    const item = _c;
                    const result = yield this.pushItem(deviceId, item);
                    if (result) {
                        done += item.items.length;
                        sendSyncProgressEvent(this.db.eventManager, "upload", done);
                        this.logger.info(`Batch sent (${done})`);
                    }
                    else {
                        this.logger.error(new Error(`Failed to send batch. Server returned falsy response.`));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_e && !_a && (_b = _f.return)) yield _b.call(_f);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (done > 0)
                yield ((_d = this.connection) === null || _d === void 0 ? void 0 : _d.send("PushCompleted"));
            return true;
        });
    }
    stop(options) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((options.type === "send" || options.type === "full") &&
                (yield this.collector.hasUnsyncedChanges())) {
                this.logger.info("Changes made during last sync. Syncing again...");
                yield this.start({ type: "send" });
                return;
            }
            // refresh monographs
            yield this.db.monographs.refresh().catch(this.logger.error);
            // update trash cache
            yield this.db.trash.buildCache();
            this.logger.info("Stopping sync");
            yield this.db.setLastSynced(Date.now());
            this.db.eventManager.publish(EVENTS.syncCompleted);
        });
    }
    cancel() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.logger.info("Sync canceled");
            yield ((_a = this.connection) === null || _a === void 0 ? void 0 : _a.stop());
        });
    }
    /**
     * @private
     */
    uploadAttachments() {
        return __awaiter(this, void 0, void 0, function* () {
            const attachments = yield this.db.attachments.pending.items();
            this.logger.info("Uploading attachments...", { total: attachments.length });
            yield this.db.fs().queueUploads(attachments.map((a) => ({
                filename: a.hash,
                chunkSize: a.chunkSize
            })), "sync-uploads");
        });
    }
    /**
     * @private
     */
    onPushCompleted() {
        return __awaiter(this, void 0, void 0, function* () {
            this.db.eventManager.publish(EVENTS.databaseSyncRequested, true, false);
        });
    }
    processChunk(chunk, key) {
        return __awaiter(this, void 0, void 0, function* () {
            const itemType = chunk.type;
            const decrypted = yield this.db.storage().decryptMulti(key, chunk.items);
            const deserialized = [];
            for (let i = 0; i < decrypted.length; ++i) {
                const decryptedItem = decrypted[i];
                const version = chunk.items[i].v;
                const item = yield deserializeItem(decryptedItem, itemType, version, this.db);
                if (item)
                    deserialized.push(item);
            }
            const collectionType = SYNC_COLLECTIONS_MAP[itemType];
            const collection = this.db[collectionType].collection;
            const localItems = yield collection.records(chunk.items.map((i) => i.id));
            let items = [];
            if (itemType === "content") {
                items = deserialized.map((item) => this.merger.mergeContent(item, localItems[item.id]));
            }
            else {
                items =
                    itemType === "attachment"
                        ? yield Promise.all(deserialized.map((item) => this.merger.mergeAttachment(item, localItems[item.id])))
                        : deserialized.map((item) => this.merger.mergeItem(item, localItems[item.id]));
            }
            if ((itemType === "note" || itemType === "content") && items.length > 0) {
                items.forEach((item) => this.db.eventManager.publish(EVENTS.syncItemMerged, item));
            }
            yield collection.put(items);
        });
    }
    pushItem(deviceId, item) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            yield this.checkConnection();
            return (yield ((_a = this.connection) === null || _a === void 0 ? void 0 : _a.invoke("PushItems", deviceId, item))) === 1;
        });
    }
    createConnection() {
        if (this.connection)
            return;
        const tokenManager = new TokenManager(this.db.kv);
        this.connection = new signalr.HubConnectionBuilder()
            .withUrl(`${Constants.API_HOST}/hubs/sync/v2`, {
            accessTokenFactory: () => __awaiter(this, void 0, void 0, function* () {
                const token = yield tokenManager.getAccessToken();
                if (!token)
                    throw new Error("Failed to get access token.");
                return token;
            }),
            skipNegotiation: true,
            transport: signalr.HttpTransportType.WebSockets,
            logger: {
                log: (level, message) => {
                    const scopedLogger = logger.scope("SignalR::SyncHub");
                    switch (level) {
                        case signalr.LogLevel.Critical:
                            return scopedLogger.fatal(new Error(message));
                        case signalr.LogLevel.Error: {
                            this.db.eventManager.publish(EVENTS.syncAborted, message);
                            return scopedLogger.error(new Error(message));
                        }
                        case signalr.LogLevel.Warning:
                            return scopedLogger.warn(message);
                    }
                }
            }
        })
            .withHubProtocol(new signalr.JsonHubProtocol())
            .build();
        this.connection.serverTimeoutInMilliseconds = 60 * 1000 * 5;
        this.connection.on("PushCompleted", () => this.onPushCompleted());
    }
    checkConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.syncConnectionMutex.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (this.connection &&
                        this.connection.state !== signalr.HubConnectionState.Connected) {
                        if (this.connection.state !== signalr.HubConnectionState.Disconnected) {
                            yield this.connection.stop();
                        }
                        yield promiseTimeout(30000, this.connection.start());
                    }
                }
                catch (e) {
                    this.logger.error(e, "Could not connect to the Sync server.");
                    if (e instanceof Error) {
                        this.logger.warn(e.message);
                        throw new Error("Could not connect to the Sync server. Please try again.");
                    }
                }
            }));
        });
    }
}
function promiseTimeout(ms, promise) {
    // Create a promise that rejects in <ms> milliseconds
    const timeout = new Promise((resolve, reject) => {
        const id = setTimeout(() => {
            clearTimeout(id);
            reject(new Error("Sync timed out in " + ms + "ms."));
        }, ms);
    });
    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
}
function deserializeItem(decryptedItem, type, version, database) {
    return __awaiter(this, void 0, void 0, function* () {
        const item = JSON.parse(decryptedItem);
        item.remote = true;
        item.synced = true;
        let migrationResult = yield migrateItem(item, version, CURRENT_DATABASE_VERSION, isDeleted(item) ? type : item.type, database, "sync");
        if (migrationResult === "skip")
            return;
        // since items in trash can have their own set of migrations,
        // we have to run the migration again to account for that.
        if (isTrashItem(item)) {
            migrationResult = yield migrateItem(item, version, CURRENT_DATABASE_VERSION, item.itemType, database, "sync");
            if (migrationResult === "skip")
                return;
        }
        const itemType = isDeleted(item)
            ? type
            : // colors are naively of type "tag" instead of "color" so we have to fix that.
                item.type === "tag" && DefaultColors[item.title.toLowerCase()]
                    ? "color"
                    : item.type === "trash" && "itemType" in item && item.itemType
                        ? item.itemType
                        : item.type;
        if (!itemType || itemType === "topic" || itemType === "settings")
            return;
        if (migrationResult)
            item.synced = false;
        return item;
    });
}
