export function isThemeColor(color, colors) {
    return color in colors;
}
export const StaticColors = {
    red: "#f44336",
    orange: "#FF9800",
    yellow: "#FFD600",
    green: "#4CAF50",
    blue: "#2196F3",
    purple: "#673AB7",
    gray: "#9E9E9E",
    black: "#000000",
    white: "#ffffff"
};
export const THEME_SCOPES = [
    "base",
    "statusBar",
    "list",
    "editor",
    "editorToolbar",
    "editorSidebar",
    "titleBar",
    "dialog",
    "navigationMenu",
    "contextMenu",
    "sheet"
];
export const COLORS = [
    "accent",
    "paragraph",
    "background",
    "border",
    "heading",
    "icon",
    "separator",
    "placeholder",
    "hover",
    "accentForeground",
    "backdrop"
];
export const ALPHA_COLORS = [
    "hover",
    "backdrop",
    "background",
    "placeholder",
    "textSelection",
    "shade"
];
export const Variants = [
    "primary",
    "secondary",
    "disabled",
    "selected",
    "error",
    "success"
];
export const DEPRECATED_COLORS = ["shade", "textSelection"];
