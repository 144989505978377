/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
export const GroupingKey = [
    "home",
    "notes",
    "notebooks",
    "tags",
    "trash",
    "favorites",
    "reminders"
];
export function isDeleted(item) {
    return !!item.deleted && item.type !== "trash";
}
export function isTrashItem(item) {
    return item.type === "trash";
}
export function isGroupHeader(item) {
    return item.type === "header";
}
export function isGroupingKey(key) {
    return GroupingKey.includes(key);
}
