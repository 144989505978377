/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import http from "../utils/http";
import constants from "../utils/constants";
import TokenManager from "./token-manager";
import { EV, EVENTS } from "../common";
import { HealthCheck } from "./healthcheck";
import { logger } from "../logger";
const ENDPOINTS = {
    signup: "/users",
    token: "/connect/token",
    user: "/users",
    deleteUser: "/users/delete",
    patchUser: "/account",
    verifyUser: "/account/verify",
    revoke: "/connect/revocation",
    recoverAccount: "/account/recover",
    resetUser: "/users/reset",
    activateTrial: "/subscriptions/trial"
};
class UserManager {
    constructor(db) {
        this.db = db;
        this.tokenManager = new TokenManager(this.db.kv);
        EV.subscribe(EVENTS.userUnauthorized, (url) => __awaiter(this, void 0, void 0, function* () {
            if (url.includes("/connect/token") || !(yield HealthCheck.auth()))
                return;
            try {
                yield this.tokenManager._refreshToken(true);
            }
            catch (e) {
                if (e instanceof Error &&
                    (e.message === "invalid_grant" || e.message === "invalid_client")) {
                    yield this.logout(false, `Your token has been revoked. Error: ${e.message}.`);
                }
            }
        }));
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.getUser();
            if (!user)
                return;
        });
    }
    signup(email, password) {
        return __awaiter(this, void 0, void 0, function* () {
            email = email.toLowerCase();
            const hashedPassword = yield this.db.storage().hash(password, email);
            yield http.post(`${constants.API_HOST}${ENDPOINTS.signup}`, {
                email,
                password: hashedPassword,
                client_id: "notesnook"
            });
            EV.publish(EVENTS.userSignedUp);
            return yield this._login({ email, password, hashedPassword });
        });
    }
    authenticateEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!email)
                throw new Error("Email is required.");
            email = email.toLowerCase();
            const result = yield http.post(`${constants.AUTH_HOST}${ENDPOINTS.token}`, {
                email,
                grant_type: "email",
                client_id: "notesnook"
            });
            yield this.tokenManager.saveToken(result);
            return result.additional_data;
        });
    }
    authenticateMultiFactorCode(code, method) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!code || !method)
                throw new Error("code & method are required.");
            const token = yield this.tokenManager.getToken();
            if (!token || token.scope !== "auth:grant_types:mfa")
                throw new Error("No token found.");
            yield this.tokenManager.saveToken(yield http.post(`${constants.AUTH_HOST}${ENDPOINTS.token}`, {
                grant_type: "mfa",
                client_id: "notesnook",
                "mfa:code": code,
                "mfa:method": method
            }, token.access_token));
            return true;
        });
    }
    authenticatePassword(email, password, hashedPassword, sessionExpired) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!email || !password)
                throw new Error("email & password are required.");
            const token = yield this.tokenManager.getToken();
            if (!token || token.scope !== "auth:grant_types:mfa_password")
                throw new Error("No token found.");
            email = email.toLowerCase();
            if (!hashedPassword) {
                hashedPassword = yield this.db.storage().hash(password, email);
            }
            try {
                yield this.tokenManager.saveToken(yield http.post(`${constants.AUTH_HOST}${ENDPOINTS.token}`, {
                    grant_type: "mfa_password",
                    client_id: "notesnook",
                    scope: "notesnook.sync offline_access IdentityServerApi",
                    password: hashedPassword
                }, token.access_token));
                const user = yield this.fetchUser();
                if (!user)
                    throw new Error("Failed to fetch user.");
                if (!sessionExpired) {
                    yield this.db.setLastSynced(0);
                    yield this.db.syncer.devices.register();
                }
                yield this.db.storage().deriveCryptoKey({
                    password,
                    salt: user.salt
                });
                EV.publish(EVENTS.userLoggedIn, user);
            }
            catch (e) {
                yield this.tokenManager.saveToken(token);
                throw e;
            }
        });
    }
    _login(_a) {
        return __awaiter(this, arguments, void 0, function* ({ email, password, hashedPassword, code, method }) {
            email = email && email.toLowerCase();
            if (!hashedPassword && password) {
                hashedPassword = yield this.db.storage().hash(password, email);
            }
            yield this.tokenManager.saveToken(yield http.post(`${constants.AUTH_HOST}${ENDPOINTS.token}`, {
                username: email,
                password: hashedPassword,
                grant_type: code ? "mfa" : "password",
                scope: "notesnook.sync offline_access IdentityServerApi",
                client_id: "notesnook",
                "mfa:code": code,
                "mfa:method": method
            }));
            const user = yield this.fetchUser();
            if (!user)
                return;
            yield this.db.storage().deriveCryptoKey({
                password,
                salt: user.salt
            });
            yield this.db.setLastSynced(0);
            yield this.db.syncer.devices.register();
            EV.publish(EVENTS.userLoggedIn, user);
        });
    }
    getSessions() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            if (!token)
                return;
            yield http.get(`${constants.AUTH_HOST}/account/sessions`, token);
        });
    }
    clearSessions() {
        return __awaiter(this, arguments, void 0, function* (all = false) {
            const token = yield this.tokenManager.getToken();
            if (!token)
                return;
            const { access_token, refresh_token } = token;
            yield http.post(`${constants.AUTH_HOST}/account/sessions/clear?all=${all}`, { refresh_token }, access_token);
        });
    }
    activateTrial() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            if (!token)
                return false;
            yield http.post(`${constants.SUBSCRIPTIONS_HOST}${ENDPOINTS.activateTrial}`, null, token);
            return true;
        });
    }
    logout() {
        return __awaiter(this, arguments, void 0, function* (revoke = true, reason) {
            try {
                yield this.db.syncer.devices.unregister();
                if (revoke)
                    yield this.tokenManager.revokeToken();
            }
            catch (e) {
                logger.error(e, "Error logging out user.", { revoke, reason });
            }
            finally {
                yield this.db.reset();
                EV.publish(EVENTS.userLoggedOut, reason);
                EV.publish(EVENTS.appRefreshRequested);
            }
        });
    }
    setUser(user) {
        return this.db.kv().write("user", user);
    }
    getUser() {
        return this.db.kv().read("user");
    }
    /**
     * @deprecated
     */
    getLegacyUser() {
        return this.db.storage().read("user");
    }
    resetUser() {
        return __awaiter(this, arguments, void 0, function* (removeAttachments = true) {
            const token = yield this.tokenManager.getAccessToken();
            if (!token)
                return;
            yield http.post(`${constants.API_HOST}${ENDPOINTS.resetUser}`, { removeAttachments }, token);
            return true;
        });
    }
    updateUser(partial) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.getUser();
            if (!user)
                return;
            const token = yield this.tokenManager.getAccessToken();
            yield http.patch.json(`${constants.API_HOST}${ENDPOINTS.user}`, partial, token);
            yield this.setUser(Object.assign(Object.assign({}, user), partial));
        });
    }
    deleteUser(password) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            const user = yield this.getUser();
            if (!token || !user)
                return;
            yield http.post(`${constants.API_HOST}${ENDPOINTS.deleteUser}`, { password: yield this.db.storage().hash(password, user.email) }, token);
            yield this.logout(false, "Account deleted.");
            return true;
        });
    }
    fetchUser() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const token = yield this.tokenManager.getAccessToken();
                if (!token)
                    return;
                const user = yield http.get(`${constants.API_HOST}${ENDPOINTS.user}`, token);
                if (user) {
                    yield this.setUser(user);
                    EV.publish(EVENTS.userFetched, user);
                    return user;
                }
                else {
                    return yield this.getUser();
                }
            }
            catch (e) {
                logger.error(e, "Error fetching user");
                return yield this.getUser();
            }
        });
    }
    changePassword(oldPassword, newPassword) {
        return this._updatePassword("change_password", {
            old_password: oldPassword,
            new_password: newPassword
        });
    }
    changeMarketingConsent(enabled) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            if (!token)
                return;
            yield http.patch(`${constants.AUTH_HOST}${ENDPOINTS.patchUser}`, {
                type: "change_marketing_consent",
                enabled: enabled
            }, token);
        });
    }
    resetPassword(newPassword) {
        return this._updatePassword("reset_password", {
            new_password: newPassword
        });
    }
    getEncryptionKey() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.getUser();
            if (!user)
                return;
            const key = yield this.db.storage().getCryptoKey();
            if (!key)
                return;
            return { key, salt: user.salt };
        });
    }
    /**
     * @deprecated
     */
    getLegacyEncryptionKey() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.getLegacyUser();
            if (!user)
                return;
            const key = yield this.db.storage().getCryptoKey();
            if (!key)
                return;
            return { key, salt: user.salt };
        });
    }
    getAttachmentsKey() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let user = yield this.getUser();
                if (!user)
                    return;
                if (!user.attachmentsKey) {
                    const token = yield this.tokenManager.getAccessToken();
                    user = yield http.get(`${constants.API_HOST}${ENDPOINTS.user}`, token);
                }
                if (!user)
                    return;
                const userEncryptionKey = yield this.getEncryptionKey();
                if (!userEncryptionKey)
                    return;
                if (!user.attachmentsKey) {
                    const key = yield this.db.crypto().generateRandomKey();
                    user.attachmentsKey = yield this.db
                        .storage()
                        .encrypt(userEncryptionKey, JSON.stringify(key));
                    yield this.updateUser({ attachmentsKey: user.attachmentsKey });
                    return key;
                }
                const plainData = yield this.db
                    .storage()
                    .decrypt(userEncryptionKey, user.attachmentsKey);
                if (!plainData)
                    return;
                return JSON.parse(plainData);
            }
            catch (e) {
                logger.error(e, "Could not get attachments encryption key.");
                if (e instanceof Error)
                    throw new Error(`Could not get attachments encryption key. Error: ${e.message}`);
            }
        });
    }
    sendVerificationEmail(newEmail) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            if (!token)
                return;
            yield http.post(`${constants.AUTH_HOST}${ENDPOINTS.verifyUser}`, { newEmail }, token);
        });
    }
    changeEmail(newEmail, password, code) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            if (!token)
                return;
            const user = yield this.getUser();
            if (!user)
                return;
            const email = newEmail.toLowerCase();
            yield http.patch(`${constants.AUTH_HOST}${ENDPOINTS.patchUser}`, {
                type: "change_email",
                new_email: newEmail,
                password: yield this.db.storage().hash(password, email),
                verification_code: code
            }, token);
            yield this.db.storage().deriveCryptoKey({
                password,
                salt: user.salt
            });
        });
    }
    recoverAccount(email) {
        return http.post(`${constants.AUTH_HOST}${ENDPOINTS.recoverAccount}`, {
            email,
            client_id: "notesnook"
        });
    }
    verifyPassword(password) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const user = yield this.getUser();
                const key = yield this.getEncryptionKey();
                if (!user || !key)
                    return false;
                const cipher = yield this.db.storage().encrypt(key, "notesnook");
                const plainText = yield this.db.storage().decrypt({ password }, cipher);
                return plainText === "notesnook";
            }
            catch (e) {
                return false;
            }
        });
    }
    _updatePassword(type, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.tokenManager.getAccessToken();
            const user = yield this.getUser();
            if (!token || !user)
                throw new Error("You are not logged in.");
            const { email, salt } = user;
            let { new_password, old_password } = data;
            if (old_password && !(yield this.verifyPassword(old_password)))
                throw new Error("Incorrect old password.");
            if (!new_password)
                throw new Error("New password is required.");
            const attachmentsKey = yield this.getAttachmentsKey();
            data.encryptionKey = data.encryptionKey || (yield this.getEncryptionKey());
            yield this.clearSessions();
            if (data.encryptionKey)
                yield this.db.sync({ type: "fetch", force: true });
            yield this.db.storage().deriveCryptoKey({
                password: new_password,
                salt
            });
            if (!(yield this.resetUser(false)))
                return;
            yield this.db.sync({ type: "send", force: true });
            if (attachmentsKey) {
                const userEncryptionKey = yield this.getEncryptionKey();
                if (!userEncryptionKey)
                    return;
                user.attachmentsKey = yield this.db
                    .storage()
                    .encrypt(userEncryptionKey, JSON.stringify(attachmentsKey));
                yield this.updateUser({ attachmentsKey: user.attachmentsKey });
            }
            if (old_password)
                old_password = yield this.db.storage().hash(old_password, email);
            if (new_password)
                new_password = yield this.db.storage().hash(new_password, email);
            yield http.patch(`${constants.AUTH_HOST}${ENDPOINTS.patchUser}`, {
                type,
                old_password,
                new_password
            }, token);
            return true;
        });
    }
}
export default UserManager;
