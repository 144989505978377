/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getId } from "../utils/id";
import { SQLCollection } from "../database/sql-collection";
import { isFalse } from "../database";
import { sql } from "kysely";
export class Tags {
    constructor(db) {
        this.db = db;
        this.name = "tags";
        this.collection = new SQLCollection(db.sql, db.transaction, "tags", db.eventManager, db.sanitizer);
    }
    init() {
        return this.collection.init();
    }
    tag(id) {
        return this.collection.get(id);
    }
    find(title) {
        return this.all.find(sql `title == ${title} COLLATE BINARY`);
    }
    add(item) {
        return __awaiter(this, void 0, void 0, function* () {
            item.title = Tags.sanitize(item.title);
            const id = item.id || getId();
            const oldTag = item.id ? yield this.tag(item.id) : undefined;
            if (oldTag && item.title === oldTag.title)
                return oldTag.id;
            if (yield this.find(item.title))
                throw new Error("Tag with this title already exists.");
            yield this.collection.upsert({
                id,
                dateCreated: item.dateCreated || (oldTag === null || oldTag === void 0 ? void 0 : oldTag.dateCreated) || Date.now(),
                dateModified: item.dateModified || (oldTag === null || oldTag === void 0 ? void 0 : oldTag.dateModified) || Date.now(),
                title: item.title || (oldTag === null || oldTag === void 0 ? void 0 : oldTag.title) || "",
                type: "tag"
            });
            return id;
        });
    }
    // get raw() {
    //   return this.collection.raw();
    // }
    get all() {
        var _a;
        return this.collection.createFilter((qb) => qb.where(isFalse("deleted")), (_a = this.db.options) === null || _a === void 0 ? void 0 : _a.batchSize);
    }
    remove(...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.db.transaction(() => __awaiter(this, void 0, void 0, function* () {
                yield this.db.relations.unlinkOfType("tag", ids);
                yield this.collection.softDelete(ids);
            }));
        });
    }
    exists(id) {
        return this.collection.exists(id);
    }
    static sanitize(title) {
        return title.replace(/^\s+|\s+$/gm, "");
    }
}
