/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getId } from "../utils/id";
import { isTrashItem } from "../types";
import { SQLCollection } from "../database/sql-collection";
import { isFalse } from "../database";
import { sql } from "kysely";
import { deleteItems } from "../utils/array";
export class Notebooks {
    constructor(db) {
        this.db = db;
        this.name = "notebooks";
        this.collection = new SQLCollection(db.sql, db.transaction, "notebooks", db.eventManager, db.sanitizer);
    }
    init() {
        return this.collection.init();
    }
    add(notebookArg) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!notebookArg)
                throw new Error("Notebook cannot be undefined or null.");
            if (notebookArg.remote)
                throw new Error("Please use db.notebooks.merge to merge remote notebooks");
            const id = notebookArg.id || getId();
            const oldNotebook = yield this.notebook(id);
            if (oldNotebook && isTrashItem(oldNotebook))
                throw new Error("Cannot modify trashed notebooks.");
            const mergedNotebook = Object.assign(Object.assign({}, oldNotebook), notebookArg);
            if (!mergedNotebook.title)
                throw new Error("Notebook must contain a title.");
            yield this.collection.upsert({
                id,
                type: "notebook",
                title: mergedNotebook.title,
                description: mergedNotebook.description,
                pinned: !!mergedNotebook.pinned,
                dateCreated: mergedNotebook.dateCreated || Date.now(),
                dateModified: mergedNotebook.dateModified || Date.now(),
                dateEdited: Date.now()
            });
            return id;
        });
    }
    // get raw() {
    //   return this.collection.raw();
    // }
    get all() {
        var _a;
        return this.collection.createFilter((qb) => qb.where(isFalse("dateDeleted")).where(isFalse("deleted")), (_a = this.db.options) === null || _a === void 0 ? void 0 : _a.batchSize);
    }
    get pinned() {
        var _a;
        return this.collection.createFilter((qb) => qb
            .where(isFalse("dateDeleted"))
            .where(isFalse("deleted"))
            .where("pinned", "==", true), (_a = this.db.options) === null || _a === void 0 ? void 0 : _a.batchSize);
    }
    // get trashed() {
    //   return this.raw.filter((item) =>
    //     isTrashItem(item)
    //   ) as BaseTrashItem<Notebook>[];
    // }
    pin(state, ...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.collection.update(ids, { pinned: state });
        });
    }
    totalNotes(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.db
                .sql()
                .withRecursive(`subNotebooks(id)`, (eb) => eb
                .selectNoFrom((eb) => eb.val(id).as("id"))
                .unionAll((eb) => eb
                .selectFrom(["relations", "subNotebooks"])
                .select("relations.toId as id")
                .where("toType", "==", "notebook")
                .where("fromType", "==", "notebook")
                .whereRef("fromId", "==", "subNotebooks.id")
                .where("toId", "not in", this.db.trash.cache.notebooks)
                .$narrowType()))
                .selectFrom("relations")
                .where("toType", "==", "note")
                .where("fromType", "==", "notebook")
                .where("fromId", "in", (eb) => eb.selectFrom("subNotebooks").select("subNotebooks.id"))
                .where("toId", "not in", this.db.trash.cache.notes)
                .select((eb) => eb.fn.count("relations.toId").as("totalNotes"))
                .executeTakeFirst();
            if (!result)
                return 0;
            return result.totalNotes;
        });
    }
    notes(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.db
                .sql()
                .withRecursive(`subNotebooks(id)`, (eb) => eb
                .selectNoFrom((eb) => eb.val(id).as("id"))
                .unionAll((eb) => eb
                .selectFrom(["relations", "subNotebooks"])
                .select("relations.toId as id")
                .where("toType", "==", "notebook")
                .where("fromType", "==", "notebook")
                .whereRef("fromId", "==", "subNotebooks.id")
                .where("toId", "not in", this.db.trash.cache.notebooks)
                .$narrowType()))
                .selectFrom("relations")
                .where("toType", "==", "note")
                .where("fromType", "==", "notebook")
                .where("fromId", "in", (eb) => eb.selectFrom("subNotebooks").select("subNotebooks.id"))
                .where("toId", "not in", this.db.trash.cache.notes)
                .select("relations.toId as id")
                .$narrowType()
                .execute();
            return result.map((i) => i.id);
        });
    }
    get roots() {
        var _a;
        return this.collection.createFilter((qb) => qb
            .where("id", "not in", (eb) => eb
            .selectFrom("relations")
            .where("toType", "==", "notebook")
            .where("fromType", "==", "notebook")
            .select("relations.toId as id")
            .$narrowType())
            .where(isFalse("dateDeleted"))
            .where(isFalse("deleted")), (_a = this.db.options) === null || _a === void 0 ? void 0 : _a.batchSize);
    }
    breadcrumbs(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const ids = yield this.db
                .sql()
                .withRecursive(`subNotebooks(id)`, (eb) => eb
                .selectNoFrom((eb) => eb.val(id).as("id"))
                .unionAll((eb) => eb
                .selectFrom(["relations", "subNotebooks"])
                .select("relations.fromId as id")
                .where("toType", "==", "notebook")
                .where("fromType", "==", "notebook")
                .whereRef("toId", "==", "subNotebooks.id")
                .where("fromId", "not in", this.db.trash.cache.notebooks)
                .$narrowType()))
                .selectFrom("subNotebooks")
                .select("id")
                .execute();
            const records = yield this.all
                .fields(["notebooks.id", "notebooks.title"])
                .records(ids.map((i) => i.id));
            return ids
                .reverse()
                .map((id) => records[id.id])
                .filter(Boolean);
        });
    }
    notebook(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const notebook = yield this.collection.get(id);
            if (!notebook || isTrashItem(notebook))
                return;
            return notebook;
        });
    }
    find(title) {
        return this.all.find((eb) => eb("notebooks.title", "==", title));
    }
    exists(id) {
        return this.all.has(id);
    }
    moveToTrash(...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.db.transaction((tr) => __awaiter(this, void 0, void 0, function* () {
                const query = tr
                    .withRecursive(`subNotebooks(id)`, (eb) => eb
                    .selectFrom((eb) => sql `(VALUES ${sql.join(ids.map((id) => eb.parens(sql `${id}`)))})`.as("notebookIds"))
                    .selectAll()
                    .unionAll((eb) => eb
                    .selectFrom(["relations", "subNotebooks"])
                    .select("relations.toId as id")
                    .where("toType", "==", "notebook")
                    .where("fromType", "==", "notebook")
                    .whereRef("fromId", "==", "subNotebooks.id")
                    .where("toId", "not in", this.db.trash.cache.notebooks)
                    .$narrowType()))
                    .selectFrom("subNotebooks")
                    .select("id");
                const subNotebookIds = (yield query.execute()).map((ref) => ref.id);
                deleteItems(subNotebookIds, ...ids);
                if (subNotebookIds.length > 0)
                    yield this.db.trash.add("notebook", subNotebookIds, "app");
                yield this.db.trash.add("notebook", ids, "user");
            }));
        });
    }
    remove(...ids) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.db.transaction(() => __awaiter(this, void 0, void 0, function* () {
                yield this.db.relations.unlinkOfType("notebook", ids);
                yield this.collection.softDelete(ids);
            }));
        });
    }
}
