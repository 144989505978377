/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { database } from "../database";
export const CURRENT_TOOLBAR_VERSION = 2;
export function migrateToolbar(platform, tools) {
    return __awaiter(this, void 0, void 0, function* () {
        const version = tools.version || 0;
        if (version === CURRENT_TOOLBAR_VERSION)
            return tools;
        tools = runMigration(version, platform, tools);
        yield database.settings.setToolbarConfig(platform, tools);
        return tools;
    });
}
function runMigration(version, platform, tools) {
    var _a, _b, _c;
    switch (version) {
        case 0: {
            (_a = tools.config) === null || _a === void 0 ? void 0 : _a.push(["checkList"]);
            return runMigration(1, platform, tools);
        }
        case 1: {
            const group = (_b = tools.config) === null || _b === void 0 ? void 0 : _b.find((g) => Array.isArray(g) && g.includes("addLink"));
            if (!group)
                (_c = tools.config) === null || _c === void 0 ? void 0 : _c.push(["addInternalLink"]);
            else if (!group.includes("addInternalLink"))
                group.push("addInternalLink");
            return runMigration(2, platform, tools);
        }
        case CURRENT_TOOLBAR_VERSION:
        default:
            break;
    }
    tools.version = CURRENT_TOOLBAR_VERSION;
    return tools;
}
