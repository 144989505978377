/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Notes } from "../collections/notes";
import { Crypto } from "../database/crypto";
import { FileStorage } from "../database/fs";
import { Notebooks } from "../collections/notebooks";
import Trash from "../collections/trash";
import Sync from "./sync";
import { Tags } from "../collections/tags";
import { Colors } from "../collections/colors";
import Vault from "./vault";
import Lookup from "./lookup";
import { Content } from "../collections/content";
import Backup from "../database/backup";
import Hosts from "../utils/constants";
import { EV, EVENTS } from "../common";
import { LegacySettings } from "../collections/legacy-settings";
import Migrations from "./migrations";
import UserManager from "./user-manager";
import http from "../utils/http";
import { Monographs } from "./monographs";
import { Offers } from "./offers";
import { Attachments } from "../collections/attachments";
import { Debug } from "./debug";
import { Mutex } from "async-mutex";
import { NoteHistory } from "../collections/note-history";
import MFAManager from "./mfa-manager";
import EventManager from "../utils/event-manager";
import { Pricing } from "./pricing";
import { logger } from "../logger";
import { Shortcuts } from "../collections/shortcuts";
import { Reminders } from "../collections/reminders";
import { Relations } from "../collections/relations";
import Subscriptions from "./subscriptions";
import TokenManager from "./token-manager";
import { Settings } from "../collections/settings";
import { changeDatabasePassword, createDatabase, initializeDatabase } from "../database";
import { sql } from "kysely";
import { CachedCollection } from "../database/cached-collection";
import { Vaults } from "../collections/vaults";
import { KVStorage } from "../database/kv";
import { Sanitizer } from "../database/sanitizer";
import { createTriggers, dropTriggers } from "../database/triggers";
import { NNMigrationProvider } from "../database/migrations";
// const DIFFERENCE_THRESHOLD = 20 * 1000;
// const MAX_TIME_ERROR_FAILURES = 5;
class Database {
    constructor() {
        this.isInitialized = false;
        this.eventManager = new EventManager();
        this.sseMutex = new Mutex();
        this.storage = () => {
            var _a;
            if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.storage))
                throw new Error("Database not initialized. Did you forget to call db.setup()?");
            return this.options.storage;
        };
        this.fs = () => {
            var _a;
            if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.fs))
                throw new Error("Database not initialized. Did you forget to call db.setup()?");
            return (this._fs ||
                (this._fs = new FileStorage(this.options.fs, this.tokenManager)));
        };
        this.crypto = () => {
            if (!this.options)
                throw new Error("Database not initialized. Did you forget to call db.setup()?");
            return new Crypto(this.storage);
        };
        this.compressor = () => {
            var _a;
            if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.compressor))
                throw new Error("Database not initialized. Did you forget to call db.setup()?");
            return this.options.compressor;
        };
        this.sql = () => {
            // if (this._transaction) return this._transaction.value;
            if (!this._sql)
                throw new Error("Database not initialized. Did you forget to call db.init()?");
            return this._sql;
        };
        this.kv = () => this._kv || new KVStorage(this.sql);
        this.transaction = (executor) => __awaiter(this, void 0, void 0, function* () {
            yield executor(this.sql());
            // if (this._transaction) {
            //   await executor(this._transaction.use()).finally(() =>
            //     this._transaction?.discard()
            //   );
            //   return;
            // }
            // return this.sql()
            //   .transaction()
            //   .execute(async (tr) => {
            //     this._transaction = new QueueValue(
            //       tr,
            //       () => (this._transaction = undefined)
            //     );
            //     await executor(this._transaction.use());
            //   })
            //   .finally(() => this._transaction?.discard());
        });
        this.tokenManager = new TokenManager(this.kv);
        this.mfa = new MFAManager(this.tokenManager);
        this.subscriptions = new Subscriptions(this.tokenManager);
        this.offers = new Offers();
        this.debug = new Debug();
        this.pricing = Pricing;
        this.user = new UserManager(this);
        this.syncer = new Sync(this);
        this.vault = new Vault(this);
        this.lookup = new Lookup(this);
        this.backup = new Backup(this);
        this.migrations = new Migrations(this);
        this.monographs = new Monographs(this);
        this.trash = new Trash(this);
        this.sanitizer = new Sanitizer(this.sql);
        this.notebooks = new Notebooks(this);
        this.tags = new Tags(this);
        this.colors = new Colors(this);
        this.content = new Content(this);
        this.attachments = new Attachments(this);
        this.noteHistory = new NoteHistory(this);
        this.shortcuts = new Shortcuts(this);
        this.reminders = new Reminders(this);
        this.relations = new Relations(this);
        this.notes = new Notes(this);
        this.vaults = new Vaults(this);
        this.settings = new Settings(this);
        /**
         * @deprecated only kept here for migration purposes
         */
        this.legacyTags = new CachedCollection(this.storage, "tags");
        /**
         * @deprecated only kept here for migration purposes
         */
        this.legacyColors = new CachedCollection(this.storage, "colors");
        /**
         * @deprecated only kept here for migration purposes
         */
        this.legacyNotes = new CachedCollection(this.storage, "notes");
        /**
         * @deprecated only kept here for migration purposes
         */
        this.legacySettings = new LegacySettings(this);
    }
    // constructor() {
    //   this.sseMutex = new Mutex();
    //   // this.lastHeartbeat = undefined; // { local: 0, server: 0 };
    //   // this.timeErrorFailures = 0;
    // }
    setup(options) {
        this.options = options;
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.storage().clear();
            yield dropTriggers(this.sql());
            for (const statement of [
                "PRAGMA writable_schema = 1",
                "DELETE FROM sqlite_master",
                "PRAGMA writable_schema = 0",
                "VACUUM",
                "PRAGMA integrity_check"
            ]) {
                yield sql.raw(statement).execute(this.sql());
            }
            yield initializeDatabase(this.sql().withTables(), new NNMigrationProvider());
            yield this.onInit(this.sql());
            yield this.initCollections();
            return true;
        });
    }
    changePassword(password) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._sql)
                return;
            yield changeDatabasePassword(this._sql, password);
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.options)
                throw new Error("options not specified. Did you forget to call db.setup()?");
            EV.subscribeMulti([EVENTS.userLoggedIn, EVENTS.userFetched, EVENTS.tokenRefreshed], this.connectSSE, this);
            EV.subscribe(EVENTS.attachmentDeleted, (attachment) => __awaiter(this, void 0, void 0, function* () {
                yield this.fs().cancel(attachment.hash);
            }));
            EV.subscribe(EVENTS.userLoggedOut, () => __awaiter(this, void 0, void 0, function* () {
                yield this.monographs.clear();
                yield this.fs().clear();
                this.disconnectSSE();
            }));
            this._sql = (yield createDatabase("notesnook", Object.assign(Object.assign({}, this.options.sqliteOptions), { migrationProvider: new NNMigrationProvider(), onInit: (db) => this.onInit(db) })));
            yield this.sanitizer.init();
            yield this.initCollections();
            yield this.migrations.init();
            this.isInitialized = true;
            if (this.migrations.required()) {
                logger.warn("Database migration is required.");
            }
        });
    }
    onInit(db) {
        return __awaiter(this, void 0, void 0, function* () {
            yield createTriggers(db);
        });
    }
    initCollections() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.legacySettings.init();
            // collections
            yield this.settings.init();
            yield this.notebooks.init();
            yield this.tags.init();
            yield this.colors.init();
            yield this.content.init();
            yield this.attachments.init();
            yield this.noteHistory.init();
            yield this.shortcuts.init();
            yield this.reminders.init();
            yield this.relations.init();
            yield this.notes.init();
            yield this.vaults.init();
            yield this.trash.init();
            // legacy collections
            yield this.legacyTags.init();
            yield this.legacyColors.init();
            yield this.legacyNotes.init();
            // we must not wait on network requests that's why
            // no await
            this.monographs.refresh().catch(logger.error);
        });
    }
    disconnectSSE() {
        if (!this.eventSource)
            return;
        this.eventSource.onopen = null;
        this.eventSource.onmessage = null;
        this.eventSource.onerror = null;
        this.eventSource.close();
        this.eventSource = null;
    }
    /**
     *
     * @param {{force: boolean, error: any}} args
     */
    connectSSE(args) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.sseMutex.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
                this.eventManager.publish(EVENTS.databaseSyncRequested, true, false);
                const forceReconnect = args && args.force;
                if (!this.EventSource ||
                    (!forceReconnect &&
                        this.eventSource &&
                        this.eventSource.readyState === this.eventSource.OPEN))
                    return;
                this.disconnectSSE();
                const token = yield this.tokenManager.getAccessToken();
                if (!token)
                    return;
                this.eventSource = new this.EventSource(`${Hosts.SSE_HOST}/sse`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                this.eventSource.onopen = () => __awaiter(this, void 0, void 0, function* () {
                    console.log("SSE: opened channel successfully!");
                });
                this.eventSource.onerror = function (error) {
                    console.log("SSE: error:", error);
                };
                this.eventSource.onmessage = (event) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const message = JSON.parse(event.data);
                        const data = JSON.parse(message.data);
                        switch (message.type) {
                            case "upgrade": {
                                const user = yield this.user.getUser();
                                if (!user)
                                    break;
                                user.subscription = data;
                                yield this.user.setUser(user);
                                EV.publish(EVENTS.userSubscriptionUpdated, data);
                                break;
                            }
                            case "logout": {
                                yield this.user.logout(true, data.reason || "Unknown.");
                                break;
                            }
                            case "emailConfirmed": {
                                yield this.tokenManager._refreshToken(true);
                                yield this.user.fetchUser();
                                EV.publish(EVENTS.userEmailConfirmed);
                                break;
                            }
                        }
                    }
                    catch (e) {
                        console.log("SSE: Unsupported message. Message = ", event.data);
                        return;
                    }
                });
            }));
        });
    }
    lastSynced() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.kv().read("lastSynced")) || 0;
        });
    }
    setLastSynced(lastSynced) {
        return this.kv().write("lastSynced", lastSynced);
    }
    sync(options) {
        return this.syncer.start(options);
    }
    host(hosts) {
        if (process.env.NODE_ENV !== "production") {
            Hosts.AUTH_HOST = hosts.AUTH_HOST || Hosts.AUTH_HOST;
            Hosts.API_HOST = hosts.API_HOST || Hosts.API_HOST;
            Hosts.SSE_HOST = hosts.SSE_HOST || Hosts.SSE_HOST;
            Hosts.SUBSCRIPTIONS_HOST =
                hosts.SUBSCRIPTIONS_HOST || Hosts.SUBSCRIPTIONS_HOST;
            Hosts.ISSUES_HOST = hosts.ISSUES_HOST || Hosts.ISSUES_HOST;
        }
    }
    version() {
        return http.get(`${Hosts.API_HOST}/version`);
    }
    announcements() {
        return __awaiter(this, void 0, void 0, function* () {
            let url = `${Hosts.API_HOST}/announcements/active`;
            const user = yield this.user.getUser();
            if (user)
                url += `?userId=${user.id}`;
            return http.get(url);
        });
    }
}
export default Database;
