/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return", awaitReturn), i[Symbol.asyncIterator] = function () { return this; }, i;
    function awaitReturn(f) { return function (v) { return Promise.resolve(v).then(f, reject); }; }
    function verb(n, f) { if (g[n]) { i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; if (f) i[n] = f(i[n]); } }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
import { parseInternalLink } from "@notesnook/core";
import { CHECK_IDS, checkIsUserPremium } from "@notesnook/core/dist/common";
import { isImage, isWebClip } from "@notesnook/core/dist/utils/filename";
import { sanitizeFilename } from "./file";
import { database } from "../database";
import { join, relative } from "pathe";
import { EMPTY_CONTENT } from "@notesnook/core/dist/collections/content";
import { getContentFromData } from "@notesnook/core/dist/content-types";
import { PathTree } from "./path-tree";
const FORMAT_TO_EXT = {
    pdf: "pdf",
    md: "md",
    txt: "txt",
    html: "html",
    "md-frontmatter": "md"
};
export function exportNotes(notes, options) {
    return __asyncGenerator(this, arguments, function* exportNotes_1() {
        var _a, e_1, _b, _c;
        const { format } = options;
        if (format !== "txt" && !(yield __await(checkIsUserPremium(CHECK_IDS.noteExport))))
            return yield __await(void 0);
        const pathTree = new PathTree();
        const notePathMap = new Map();
        try {
            for (var _d = true, _e = __asyncValues(notes.fields(["notes.id", "notes.title"])), _f; _f = yield __await(_e.next()), _a = _f.done, !_a; _d = true) {
                _c = _f.value;
                _d = false;
                const note = _c;
                const filename = `${sanitizeFilename(note.title, { replacement: "-" })}.${FORMAT_TO_EXT[format]}`;
                const notebooks = yield __await(database.relations
                    .to({ id: note.id, type: "note" }, "notebook")
                    .get());
                const filePaths = [];
                for (const { fromId: notebookId } of notebooks) {
                    const crumbs = (yield __await(database.notebooks.breadcrumbs(notebookId))).map((n) => n.title);
                    filePaths.push([...crumbs, filename].join("/"));
                }
                if (filePaths.length === 0)
                    filePaths.push(filename);
                notePathMap.set(note.id, filePaths.map((p) => pathTree.add(p)));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = _e.return)) yield __await(_b.call(_e));
            }
            finally { if (e_1) throw e_1.error; }
        }
        // case where the user has a notebook named attachments
        const attachmentsRoot = pathTree.add("attachments", "underscore");
        const pendingAttachments = new Map();
        for (const [id] of notePathMap) {
            const note = yield __await(database.notes.note(id));
            if (!note)
                continue;
            const notePaths = notePathMap.get(note.id);
            if (!notePaths) {
                yield yield __await(new Error("Cannot export note because it has unresolved paths."));
                continue;
            }
            try {
                const content = yield __await(exportContent(note, {
                    unlockVault: options.unlockVault,
                    format,
                    attachmentsRoot,
                    pendingAttachments,
                    resolveInternalLink: (link) => {
                        const internalLink = parseInternalLink(link);
                        if (!internalLink)
                            return link;
                        const paths = notePathMap.get(internalLink.id);
                        if (!paths)
                            return link;
                        // if the internal link is linking within the same note
                        if (paths === notePaths)
                            return `{{NOTE_PATH:}}`;
                        return `{{NOTE_PATH:${paths[0]}}}`;
                    }
                }));
                if (!content)
                    continue;
                for (const path of notePaths) {
                    yield yield __await({
                        type: "note",
                        path,
                        data: resolvePaths(content, path),
                        mtime: new Date(note.dateEdited),
                        ctime: new Date(note.dateCreated)
                    });
                }
            }
            catch (e) {
                yield yield __await(new Error(`Failed to export note "${note.title}": ${e.message}`));
            }
        }
        for (const [path, attachment] of pendingAttachments) {
            yield yield __await({
                type: "attachment",
                path,
                data: attachment,
                mtime: new Date(attachment.dateModified),
                ctime: new Date(attachment.dateCreated)
            });
        }
    });
}
export function exportNote(note, options) {
    return __asyncGenerator(this, arguments, function* exportNote_1() {
        const { format } = options;
        if (format !== "txt" && !(yield __await(checkIsUserPremium(CHECK_IDS.noteExport))))
            return yield __await(void 0);
        const attachmentsRoot = "attachments";
        const filename = sanitizeFilename(note.title, { replacement: "-" });
        const ext = FORMAT_TO_EXT[options.format];
        const path = [filename, ext].join(".");
        const pendingAttachments = new Map();
        try {
            const content = yield __await(exportContent(note, {
                format,
                attachmentsRoot,
                pendingAttachments,
                unlockVault: options.unlockVault
            }));
            if (!content)
                return yield __await(false);
            yield yield __await({
                type: "note",
                path,
                data: resolvePaths(content, path),
                mtime: new Date(note.dateEdited),
                ctime: new Date(note.dateCreated)
            });
            for (const [path, attachment] of pendingAttachments) {
                yield yield __await({
                    type: "attachment",
                    path,
                    data: attachment,
                    mtime: new Date(attachment.dateModified),
                    ctime: new Date(attachment.dateCreated)
                });
            }
        }
        catch (e) {
            yield yield __await(new Error(`Failed to export note "${note.title}": ${e.message}`));
        }
    });
}
export function exportContent(note, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { format, unlockVault, resolveInternalLink, attachmentsRoot, pendingAttachments, disableTemplate } = options;
        const rawContent = yield database.content.findByNoteId(note.id);
        if ((rawContent === null || rawContent === void 0 ? void 0 : rawContent.locked) &&
            !database.vault.unlocked &&
            !(yield (unlockVault === null || unlockVault === void 0 ? void 0 : unlockVault()))) {
            throw new Error(`Could not export locked note: "${note.title}".`);
        }
        const contentItem = (rawContent === null || rawContent === void 0 ? void 0 : rawContent.locked)
            ? yield database.vault.decryptContent(rawContent)
            : // .catch((e) => {
                //     console.error(e, note);
                //     return <NoteContent<false>>{
                //       type: "tiptap",
                //       data: `This note could not be decrypted: ${e}`
                //     };
                //   })
                rawContent;
        const { data, type } = format === "pdf"
            ? yield database.content.downloadMedia(`export-${note.id}`, contentItem || EMPTY_CONTENT(note.id), false)
            : contentItem || EMPTY_CONTENT(note.id);
        const content = getContentFromData(type, data);
        if (resolveInternalLink)
            content.resolveInternalLinks(resolveInternalLink);
        if (attachmentsRoot &&
            pendingAttachments &&
            format !== "txt" &&
            format !== "pdf") {
            yield content.resolveAttachments((elements) => __awaiter(this, void 0, void 0, function* () {
                const hashes = Object.keys(elements);
                const attachments = yield database.attachments.all
                    .where((eb) => eb("attachments.hash", "in", hashes))
                    .items();
                const sources = {};
                for (const attachment of attachments) {
                    const filename = [attachment.hash, attachment.filename].join("-");
                    const attachmentPath = join(attachmentsRoot, filename);
                    sources[attachment.hash] = resolveAttachment(elements, attachment, attachmentPath, format);
                    pendingAttachments.set(attachmentPath, attachment);
                }
                return sources;
            }));
        }
        const exported = yield database.notes.export(note, {
            disableTemplate,
            format: format === "pdf" ? "html" : format,
            rawContent: format === "html" || format === "pdf"
                ? content.toHTML()
                : format === "md" || format === "md-frontmatter"
                    ? content.toMD()
                    : content.toTXT()
        });
        if (typeof exported === "boolean" && !exported)
            return;
        return exported;
    });
}
function resolveAttachment(elements, attachment, attachmentPath, format) {
    const relativePath = `{{NOTE_PATH:${attachmentPath}}}`;
    const attributes = elements[attachment.hash];
    if (isImage(attachment.mimeType)) {
        const classes = [];
        if (attributes["data-float"] === "true")
            classes.push("float");
        if (attributes["data-align"] === "right")
            classes.push("align-right");
        if (attributes["data-align"] === "center")
            classes.push("align-center");
        return `<img class="${classes.join(" ")}" src="${relativePath}" alt="${attachment.filename}" width="${attributes.width}" height="${attributes.height}" />`;
    }
    // markdown doesn't allow arbitrary iframes in its html so no need
    // to support that
    else if (isWebClip(attachment.mimeType) && format === "html") {
        return `<iframe src="${relativePath} "width="${attributes.width}" height="${attributes.height}" />`;
    }
    return `<a href="${relativePath}" title="${attachment.filename}">${attachment.filename}</a>`;
}
function resolvePaths(content, path) {
    return content.replace(/\{\{NOTE_PATH:(.+?)\}\}/gm, (str, ...args) => {
        console.log(str, args);
        const [to] = args;
        if (!to)
            return path;
        return relative(path, to).replace(/^..\//, "./");
    });
}
