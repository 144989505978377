/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sql } from "kysely";
import { rebuildSearchIndex } from "./fts";
const COLLATE_NOCASE = (col) => col.modifyEnd(sql `collate nocase`);
export class NNMigrationProvider {
    getMigrations() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                "1": {
                    up(db) {
                        return __awaiter(this, void 0, void 0, function* () {
                            yield db.schema
                                .createTable("kv")
                                .modifyEnd(sql `without rowid`)
                                .addColumn("key", "text", (c) => c.primaryKey().unique().notNull())
                                .addColumn("value", "text")
                                .addColumn("dateModified", "integer")
                                .execute();
                            yield db.schema
                                .createTable("notes")
                                // .modifyEnd(sql`without rowid`)
                                .$call(addBaseColumns)
                                .$call(addTrashColumns)
                                .addColumn("title", "text", COLLATE_NOCASE)
                                .addColumn("headline", "text")
                                .addColumn("contentId", "text")
                                .addColumn("pinned", "boolean")
                                .addColumn("favorite", "boolean")
                                .addColumn("localOnly", "boolean")
                                .addColumn("conflicted", "boolean")
                                .addColumn("readonly", "boolean")
                                .addColumn("dateEdited", "integer")
                                .execute();
                            yield createFTS5Table("notes_fts", [{ name: "id" }, { name: "title" }], { contentTable: "notes", tokenizer: ["porter", "trigram"] }).execute(db);
                            yield db.schema
                                .createTable("content")
                                // .modifyEnd(sql`without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("noteId", "text")
                                .addColumn("data", "text")
                                .addColumn("locked", "boolean")
                                .addColumn("localOnly", "boolean")
                                .addColumn("conflicted", "text")
                                .addColumn("sessionId", "text")
                                .addColumn("dateEdited", "integer")
                                .addColumn("dateResolved", "integer")
                                .execute();
                            yield createFTS5Table("content_fts", [{ name: "id" }, { name: "noteId" }, { name: "data" }], { contentTable: "content", tokenizer: ["porter", "trigram"] }).execute(db);
                            yield db.schema
                                .createTable("notehistory")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("noteId", "text")
                                .addColumn("sessionContentId", "text")
                                .addColumn("localOnly", "boolean")
                                .addColumn("locked", "boolean")
                                .execute();
                            yield db.schema
                                .createTable("sessioncontent")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("data", "text")
                                .addColumn("contentType", "text")
                                .addColumn("locked", "boolean")
                                .addColumn("compressed", "boolean")
                                .addColumn("localOnly", "boolean")
                                .execute();
                            yield db.schema
                                .createTable("notebooks")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .$call(addTrashColumns)
                                .addColumn("title", "text", COLLATE_NOCASE)
                                .addColumn("description", "text")
                                .addColumn("dateEdited", "integer")
                                .addColumn("pinned", "boolean")
                                .execute();
                            yield db.schema
                                .createTable("tags")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("title", "text", COLLATE_NOCASE)
                                .execute();
                            yield db.schema
                                .createTable("colors")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("title", "text", COLLATE_NOCASE)
                                .addColumn("colorCode", "text", (c) => c.unique())
                                .execute();
                            yield db.schema
                                .createTable("vaults")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("title", "text", COLLATE_NOCASE)
                                .addColumn("key", "text")
                                .execute();
                            yield db.schema
                                .createTable("relations")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("fromType", "text")
                                .addColumn("fromId", "text")
                                .addColumn("toType", "text")
                                .addColumn("toId", "text")
                                .execute();
                            yield db.schema
                                .createTable("shortcuts")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("sortIndex", "integer")
                                .addColumn("itemId", "text")
                                .addColumn("itemType", "text")
                                .execute();
                            yield db.schema
                                .createTable("reminders")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("title", "text", COLLATE_NOCASE)
                                .addColumn("description", "text")
                                .addColumn("priority", "text")
                                .addColumn("date", "integer")
                                .addColumn("mode", "text")
                                .addColumn("recurringMode", "text")
                                .addColumn("selectedDays", "text")
                                .addColumn("localOnly", "boolean")
                                .addColumn("disabled", "boolean")
                                .addColumn("snoozeUntil", "integer")
                                .execute();
                            yield db.schema
                                .createTable("attachments")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("iv", "text")
                                .addColumn("salt", "text")
                                .addColumn("size", "integer")
                                .addColumn("alg", "text")
                                .addColumn("key", "text")
                                .addColumn("chunkSize", "integer")
                                .addColumn("hash", "text", (c) => c.unique())
                                .addColumn("hashType", "text")
                                .addColumn("mimeType", "text")
                                .addColumn("filename", "text")
                                .addColumn("dateDeleted", "integer")
                                .addColumn("dateUploaded", "integer")
                                .addColumn("failed", "text")
                                .execute();
                            yield db.schema
                                .createTable("settings")
                                .modifyEnd(sql `without rowid`)
                                .$call(addBaseColumns)
                                .addColumn("key", "text", (c) => c.unique())
                                .addColumn("value", "text")
                                .execute();
                            yield db.schema
                                .createIndex("notehistory_noteid")
                                .on("notehistory")
                                .column("noteId")
                                .execute();
                            yield db.schema
                                .createIndex("relation_from_general")
                                .on("relations")
                                .columns(["fromType", "toType", "fromId"])
                                .where("toType", "!=", "note")
                                .where("toType", "!=", "notebook")
                                .execute();
                            yield db.schema
                                .createIndex("relation_to_general")
                                .on("relations")
                                .columns(["fromType", "toType", "toId"])
                                .where("fromType", "!=", "note")
                                .where("fromType", "!=", "notebook")
                                .execute();
                            yield db.schema
                                .createIndex("relation_from_note_notebook")
                                .on("relations")
                                .columns(["fromType", "toType", "fromId", "toId"])
                                .where((eb) => eb.or([
                                eb("toType", "==", "note"),
                                eb("toType", "==", "notebook")
                            ]))
                                .execute();
                            yield db.schema
                                .createIndex("relation_to_note_notebook")
                                .on("relations")
                                .columns(["fromType", "toType", "toId", "fromId"])
                                .where((eb) => eb.or([
                                eb("fromType", "==", "note"),
                                eb("fromType", "==", "notebook")
                            ]))
                                .execute();
                            yield db.schema
                                .createIndex("note_type")
                                .on("notes")
                                .columns(["type"])
                                .execute();
                            yield db.schema
                                .createIndex("note_deleted")
                                .on("notes")
                                .columns(["deleted"])
                                .execute();
                            yield db.schema
                                .createIndex("note_date_deleted")
                                .on("notes")
                                .columns(["dateDeleted"])
                                .execute();
                            yield db.schema
                                .createIndex("notebook_type")
                                .on("notebooks")
                                .columns(["type"])
                                .execute();
                            yield db.schema
                                .createIndex("attachment_hash")
                                .on("attachments")
                                .column("hash")
                                .execute();
                            yield db.schema
                                .createIndex("content_noteId")
                                .on("content")
                                .columns(["noteId"])
                                .execute();
                        });
                    },
                    down(db) {
                        return __awaiter(this, void 0, void 0, function* () { });
                    }
                },
                "2": {
                    up(db) {
                        return __awaiter(this, void 0, void 0, function* () {
                            yield rebuildSearchIndex(db);
                        });
                    }
                },
                "3": {
                    up(db) {
                        return __awaiter(this, void 0, void 0, function* () {
                            yield db
                                .updateTable("notes")
                                .where("id", "in", (eb) => eb
                                .selectFrom("content")
                                .select("noteId as id")
                                .where((eb) => eb.or([
                                eb("conflicted", "is", null),
                                eb("conflicted", "==", false)
                            ]))
                                .$castTo())
                                .set({ conflicted: false })
                                .execute();
                        });
                    }
                }
            };
        });
    }
}
const addBaseColumns = (builder) => {
    return builder
        .addColumn("id", "text", (c) => c.primaryKey().unique().notNull())
        .addColumn("type", "text")
        .addColumn("dateModified", "integer")
        .addColumn("dateCreated", "integer")
        .addColumn("synced", "boolean")
        .addColumn("deleted", "boolean");
};
const addTrashColumns = (builder) => {
    return builder
        .addColumn("dateDeleted", "integer")
        .addColumn("itemType", "text")
        .addColumn("deletedBy", "text");
};
function createFTS5Table(name, columns, options = {}) {
    const _options = [];
    if (options.contentTable)
        _options.push(`content='${options.contentTable}'`);
    if (options.contentTableRowId)
        _options.push(`content_rowid='${options.contentTableRowId}'`);
    if (options.tokenizer)
        _options.push(`tokenize='${options.tokenizer.join(" ")}'`);
    if (options.prefix)
        _options.push(`prefix='${options.prefix.join(" ")}'`);
    if (options.columnSize)
        _options.push(`columnsize='${options.columnSize}'`);
    if (options.detail)
        _options.push(`detail='${options.detail}'`);
    const args = sql.join([
        sql.join(columns.map((c) => sql.ref(`${c.name}${c.unindexed ? " UNINDEXED" : ""}`))),
        sql.join(_options.map((o) => sql.raw(o)))
    ]);
    return sql `CREATE VIRTUAL TABLE ${sql.raw(name)} USING fts5(${args})`;
}
