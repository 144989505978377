/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
const defaultVariant = {
    color: "paragraph",
    fontFamily: "body"
};
const heading = {
    variant: "text.default",
    color: "heading",
    fontFamily: "heading",
    fontWeight: "bold",
    fontSize: "heading"
};
const title = {
    variant: "text.heading",
    color: "heading",
    fontSize: "title",
    fontWeight: "bold"
};
const subtitle = {
    variant: "text.heading",
    color: "heading",
    fontSize: "subtitle",
    fontWeight: "bold"
};
const body = { variant: "text.default", fontSize: "body" };
const subBody = {
    variant: "text.default",
    fontSize: "subBody",
    color: "paragraph-secondary"
};
const error = {
    variant: "text.default",
    fontSize: "subBody",
    color: "paragraph-error"
};
export const textVariants = {
    default: defaultVariant,
    heading,
    title,
    subtitle,
    body,
    subBody,
    error
};
