/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
/**
 * Takes a function that creates a Promise and returns its pending, fulfilled, or rejected result.
 *
 * ```ts
 * const result = usePromise(() => fetch('/api/products'))
 * ```
 *
 * Also takes a list of dependencies, when the dependencies change the promise is recreated.
 *
 * ```ts
 * const result = usePromise(() => fetch(`/api/products/${id}`), [id])
 * ```
 *
 * Can abort a fetch request, a [signal](https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal) is provided from the factory function to do so.
 *
 * ```ts
 * const result = usePromise(signal => fetch(`/api/products/${id}`, { signal }), [id])
 * ```
 *
 * @param factory Function that creates the promise.
 * @param deps If present, promise will be recreated if the values in the list change.
 */
export function usePromise(factory, deps = []) {
    const [result, setResult] = useState({ status: "pending" });
    useEffect(function effect() {
        if (result.status !== "pending") {
            setResult((s) => (Object.assign(Object.assign({}, s), { status: "pending" })));
        }
        const controller = new AbortController();
        const { signal } = controller;
        function handlePromise() {
            return __awaiter(this, void 0, void 0, function* () {
                const [promiseResult] = yield Promise.allSettled([factory(signal)]);
                if (!signal.aborted) {
                    setResult(Object.assign(Object.assign({}, promiseResult), { refresh: effect }));
                }
            });
        }
        handlePromise();
        return () => controller.abort();
    }, deps);
    return result;
}
