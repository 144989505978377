/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
import { consoleReporter } from "./reporters/console";
import { LogLevel } from "./types";
export class Logger {
    constructor(config = {
        reporter: consoleReporter,
        lastTime: Date.now()
    }) {
        this.config = config;
        this.fatal = errorLogLevelFactory(LogLevel.Fatal, this.config);
        this.warn = logLevelFactory(LogLevel.Warn, this.config);
        this.debug = logLevelFactory(LogLevel.Debug, this.config);
        this.error = errorLogLevelFactory(LogLevel.Error, this.config);
        this.info = logLevelFactory(LogLevel.Info, this.config);
        this.log = logLevelFactory(LogLevel.Log, this.config);
    }
    scope(scope) {
        return new Logger(Object.assign(Object.assign({}, this.config), { scope }));
    }
    measure(tag) {
        performance.mark(tag);
        const marks = performance.getEntriesByName(tag, "mark");
        if (marks.length === 2) {
            const duration = marks[1].startTime - marks[0].startTime;
            this.info(`${tag} took ${duration.toFixed(2)}ms`);
            performance.clearMarks(tag);
        }
    }
}
export class NoopLogger {
    fatal() { }
    warn() { }
    debug() { }
    error() { }
    info() { }
    log() { }
    measure() { }
    scope() {
        return this;
    }
    replaceWith(logger) {
        Object.assign(this, logger);
    }
}
export * from "./types";
export * from "./reporters";
function logLevelFactory(level, config) {
    return (message, extras) => {
        const now = Date.now();
        config.reporter.write({
            level,
            message,
            timestamp: now,
            extras,
            scope: config.scope,
            elapsed: now - config.lastTime
        });
        config.lastTime = now;
    };
}
function errorLogLevelFactory(level, config) {
    return (error, fallbackMessage, extras) => {
        const now = Date.now();
        config.reporter.write({
            level,
            message: error instanceof Error && error.stack
                ? error.stack.trim()
                : fallbackMessage
                    ? fallbackMessage
                    : "An unknown error occurred.",
            timestamp: now,
            extras: error instanceof Error
                ? Object.assign(Object.assign({}, extras), { fallbackMessage }) : error
                ? Object.assign(Object.assign({}, extras), { error }) : extras,
            scope: config.scope,
            elapsed: now - config.lastTime
        });
        config.lastTime = now;
    };
}
export function combineReporters(reporters) {
    return {
        write(log) {
            for (const reporter of reporters) {
                reporter.write(log);
            }
        }
    };
}
