/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
/* eslint-disable @typescript-eslint/no-explicit-any */
export function debounce(func, waitFor) {
    let timeout;
    return (...args) => {
        if (timeout)
            clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };
}
export function debounceWithId(func, waitFor) {
    let timeout;
    let debounceId = null;
    return (id, ...args) => {
        if (timeout && id === debounceId)
            clearTimeout(timeout);
        debounceId = id;
        timeout = setTimeout(() => {
            func(...args);
        }, waitFor);
    };
}
const DEBOUNCE_TIMEOUTS = {};
export function inlineDebounce(id, func, waitFor) {
    clearTimeout(DEBOUNCE_TIMEOUTS[id]);
    DEBOUNCE_TIMEOUTS[id] = setTimeout(func, waitFor);
}
