var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
import { ThemeProvider } from "@theme-ui/core";
import { useThemeColors, useThemeEngineStore, ScopedThemeProvider as NNScopedThemeProvider, ThemeFactory } from "../";
import React, { useMemo } from "react";
import { Box } from "@theme-ui/components";
import { useTheme } from "@emotion/react";
function _EmotionThemeProvider(props, forwardedRef) {
    const { children, scope = "base", injectCssVars = true, className, theme: partialTheme } = props, restProps = __rest(props, ["children", "scope", "injectCssVars", "className", "theme"]);
    const emotionTheme = useTheme();
    const theme = useThemeEngineStore((store) => store.theme);
    const themeScope = useThemeColors(scope);
    const { colors } = themeScope;
    const themeProperties = useMemo(() => ThemeFactory.construct({
        scope: colors,
        colorScheme: theme.colorScheme
    }), [colors, theme.colorScheme]);
    return (_jsx(ThemeProvider, { theme: Object.assign(Object.assign(Object.assign({}, (emotionTheme && "space" in emotionTheme
            ? emotionTheme
            : themeProperties)), partialTheme), { colors: themeProperties.colors }), children: _jsx(NNScopedThemeProvider, { value: scope, children: injectCssVars ? (_jsx(Box, Object.assign({}, restProps, { ref: forwardedRef, className: `${className ? className + " " : ""}theme-scope-${scope}`, children: children }))) : (children) }) }));
}
export const EmotionThemeProvider = React.forwardRef(_EmotionThemeProvider);
