/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return", awaitReturn), i[Symbol.asyncIterator] = function () { return this; }, i;
    function awaitReturn(f) { return function (v) { return Promise.resolve(v).then(f, reject); }; }
    function verb(n, f) { if (g[n]) { i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; if (f) i[n] = f(i[n]); } }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
import { toChunks } from "../utils/array";
import Indexer from "./indexer";
/**
 * @deprecated only kept here for migration purposes
 */
export class IndexedCollection {
    constructor(storage, type) {
        this.indexer = new Indexer(storage, type);
    }
    clear() {
        return this.indexer.clear();
    }
    deleteItem(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.indexer.deindex(id);
            return yield this.indexer.remove(id);
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.indexer.init();
        });
    }
    addItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.indexer.write(item.id, item);
            yield this.indexer.index(item.id);
        });
    }
    exists(id) {
        return this.indexer.exists(id);
    }
    iterate(chunkSize) {
        return __asyncGenerator(this, arguments, function* iterate_1() {
            const chunks = toChunks(this.indexer.indices, chunkSize);
            for (const chunk of chunks) {
                yield yield __await(yield __await(this.indexer.readMulti(chunk)));
            }
        });
    }
}
