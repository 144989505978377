
      import { themeToCSS } from "@notesnook/theme";

      const colorScheme = JSON.parse(
        window.localStorage.getItem("colorScheme") || '"light"'
      );
      const root = document.querySelector("html");
      if (root) root.setAttribute("data-theme", colorScheme);

      const theme = window.localStorage.getItem(`theme:${colorScheme}`);
      if (theme) {
        const css = themeToCSS(JSON.parse(theme));
        const stylesheet = document.getElementById("theme-colors");
        if (stylesheet) stylesheet.innerHTML = css;
      }
    