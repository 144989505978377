/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import http from "../utils/http";
import Constants from "../utils/constants";
import { isDeleted } from "../types";
import { isFalse } from "../database";
import { logger } from "../logger";
export class Monographs {
    constructor(db) {
        this.db = db;
        this.monographs = [];
    }
    clear() {
        return __awaiter(this, void 0, void 0, function* () {
            this.monographs = [];
            yield this.db.kv().write("monographs", this.monographs);
        });
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const user = yield this.db.user.getUser();
                const token = yield this.db.tokenManager.getAccessToken();
                if (!user || !token || !user.isEmailConfirmed)
                    return;
                const monographs = yield http.get(`${Constants.API_HOST}/monographs`, token);
                yield this.db.kv().write("monographs", monographs);
                if (monographs)
                    this.monographs = monographs;
            }
            catch (e) {
                logger.error(e, "Error while refreshing monographs.");
            }
        });
    }
    /**
     * Check if note is published.
     */
    isPublished(noteId) {
        return this.monographs && this.monographs.indexOf(noteId) > -1;
    }
    /**
     * Get note published monograph id
     */
    monograph(noteId) {
        return this.monographs[this.monographs.indexOf(noteId)];
    }
    /**
     * Publish a note as a monograph
     */
    publish(noteId_1) {
        return __awaiter(this, arguments, void 0, function* (noteId, opts = {}) {
            if (!this.monographs.length)
                yield this.refresh();
            const update = !!this.isPublished(noteId);
            const user = yield this.db.user.getUser();
            const token = yield this.db.tokenManager.getAccessToken();
            if (!user || !token)
                throw new Error("Please login to publish a note.");
            const note = yield this.db.notes.note(noteId);
            if (!note)
                throw new Error("No such note found.");
            if (!note.contentId)
                throw new Error("Cannot publish an empty note.");
            const contentItem = yield this.db.content.get(note.contentId);
            if (!contentItem || isDeleted(contentItem))
                throw new Error("Could not find content for this note.");
            if (contentItem.locked)
                throw new Error("Cannot published locked notes.");
            const content = yield this.db.content.downloadMedia(`monograph-${noteId}`, contentItem, false);
            const monograph = Object.assign({ id: noteId, title: note.title, userId: user.id, selfDestruct: opts.selfDestruct || false }, (opts.password
                ? {
                    encryptedContent: yield this.db
                        .storage()
                        .encrypt({ password: opts.password }, JSON.stringify({ type: content.type, data: content.data }))
                }
                : {
                    content: JSON.stringify({
                        type: content.type,
                        data: content.data
                    })
                }));
            const method = update ? http.patch.json : http.post.json;
            const { id } = yield method(`${Constants.API_HOST}/monographs`, monograph, token);
            this.monographs.push(id);
            return id;
        });
    }
    /**
     * Unpublish a note
     */
    unpublish(noteId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.monographs.length)
                yield this.refresh();
            const user = yield this.db.user.getUser();
            const token = yield this.db.tokenManager.getAccessToken();
            if (!user || !token)
                throw new Error("Please login to publish a note.");
            if (!this.isPublished(noteId))
                throw new Error("This note is not published.");
            yield http.delete(`${Constants.API_HOST}/monographs/${noteId}`, token);
            this.monographs.splice(this.monographs.indexOf(noteId), 1);
        });
    }
    get all() {
        var _a;
        return this.db.notes.collection.createFilter((qb) => qb
            .where(isFalse("dateDeleted"))
            .where(isFalse("deleted"))
            .where("id", "in", this.monographs), (_a = this.db.options) === null || _a === void 0 ? void 0 : _a.batchSize);
    }
    get(monographId) {
        return http.get(`${Constants.API_HOST}/monographs/${monographId}`);
    }
}
